<template>
  <div class="layout">
    <div style="z-index: 105 !important;">
      <SideNavbar @button-click="langchange"></SideNavbar>
    </div>
    <div class="faq_contents">
      <BackButton @click="goBack" :message="this.lang.back_button"></BackButton>
      <div class="faq_header">
        Frequently Asked Questions (FAQ)
      </div>
      <div class="dashboard_role">
          Current Role : {{ this.currentRole }}
      </div>
      <div class="faq_secondary_header">
        Here are the list of our Frequently Asked Questions (FAQ).
      </div>
      <div class="faq_container">
        <div v-for="index in arrayLength" :key="index">
          <p class="qtext">{{ index }}. {{ this.lang.question[index-1] }}</p>
          <div v-for="index2 in this.lang.explaination[index-1].length" :key="index2">
            <div v-if="hasImgTag(this.lang.explaination[index-1][index2-1])">
              <div v-html=this.lang.explaination[index-1][index2-1]></div>
            </div>
            <div v-else>
              <p class="atext" style="padding-left: 20px;">{{ this.lang.explaination[index-1][index2-1] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BottomFooter ref="BottomFooterRef"></BottomFooter>
  </template>
    
<script>
  import BackButton from './BackButton.vue'
  import SideNavbar from './SideNavbar.vue';
  import BottomFooter from './BottomFooter.vue';

  export default {
    data(){
      return{
        arrayLength: 0,
        arrayLength2: 0,
        lang:{
            title:"",
            second_title:"",
            content:"",
            question:[],
            explaination:[],
        },
        data:{},
        currentRole: localStorage.role,
      }
    },
    components: {
      SideNavbar,
      BackButton,
      BottomFooter
    },
    methods: {
      hasImgTag(content) {
        const imgTagPattern = /<img\s+[^>]*src=["']([^"']+)["'][^>]*>/;
        return imgTagPattern.test(content);
      },
      goBack() {
        this.$router.replace('/dashboard'); // Go back one step in the history
      },
      async langchange(picker){
          console.log(picker)
          console.log(this.data)
          const response = await fetch("/faq.json");
          const data = await response.json();
          this.data = data
          this.lang = this.data[picker]
          this.arrayLength = this.data[picker].question.length;
          this.$refs.BottomFooterRef.langchange(picker);
      }
    }
  }
</script>
    
<style scoped>
  .layout{
      display: flex;
  }
  .faq_contents{
      flex: 1;
      padding-left: 16px;
      font-family: 'Century Gothic';
  }
  .faq_header{
      font-family: 'Century Gothic Bold';
      font-weight: bold;
      font-size: 24px;
      margin-top: 11px;
  }
  .faq_secondary_header{
      font-family: 'Century Gothic Bold';
      font-weight: bold;
      font-size: 16px;
      margin-top: 15px;
  }
  .faq_container{
    text-align: justify;
    margin-top: 20px;
    z-index: 95;
    height: 75vh;
    max-width: 100%;
    overflow-y: auto;
  }
  .faq_container::-webkit-scrollbar{
        width: 8px;
  }
  .faq_container::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 4px;
  }
  .faq_container::-webkit-scrollbar-track {
      background-color: #eee;
      border-radius: 4px;
  }
  .qtext{
    font-family: 'Century Gothic Bold';
    color: #013042;
    font-weight: 700;
    font-size: 18px;
  }
  .atext{
  color: #013042;
  font-size: 16px;
  margin-top: -15px;
  }
  @media(min-width: 1500px){
    .faq_container{
        height: 80vh;
    }
  }
  @media(min-width: 1900px){
    .faq_container{
        height: 80vh;
    }
  }
  @media(max-width: 376px){
    .faq_header{
      font-size: 18px;
    }
    .faq_secondary_header{
      font-size: 15px;
    }
    .qtext{
      font-size: 15px;
    }
    .atext{
      font-size: 14px;
    }
  }
</style>
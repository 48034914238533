<template>
<div class="layout">
    <div class="overlay" v-if="overlayup"></div>
    <div>
        <SideNavbar></SideNavbar>
    </div>
    <div class="regdevice_contents">
        <BackButton @click="goBack"></BackButton>
        <div class="regdevice_header">
            Profile
        </div>
        <div class="regdevice_secondary_container">
            <p class="regdevice_secondary_header">Here are the details of your profile.</p>
        </div>
        <div class="regdevice_container">
            <div class="card">
                <div class="card_content">
                    <div class="device_info">
                        <img src="../assets/profile-user.png" alt="Device Picture" class="device_image"/>
                        <div>
                            <h2 class="card_devicename_text">SLA Admin</h2>
                            <p class="card_details_text"><strong class="card_details_strong">Account Creation Date:</strong> 10/08/2023</p>
                            <p class="card_details_text"><strong class="card_details_strong">Account Status:</strong> Active</p>
                            <p class="card_details_text"><strong class="card_details_strong">Last Login:</strong> 1 minute(s) ago</p>
                        </div>
                    </div>
                </div>
                <div class="button_container">
                    <button type="submit" class="lostmode_btn" @click="editProfile">Edit Profile</button>
                    <div class="removebtn">
                        <button type="submit" class="removedevice_btn" @click="logout">Logout</button>
                    </div>
                </div>
            </div>
        </div>
        <!--Lost Mode Enable Form-->
        <div class="lostmode-card" v-if="editprofile">
            <div class="row">
                <div class="lostmode-text">
                    Edit Profile    
                </div>
                <div class="lostmode-content">
                    <form class="col-md-6">
                        <div class="form-group">
                            <label class="input_header_style" for="newuname">New Username</label>
                            <input type="text" class="form-control contact_input_form" id="newuname" placeholder="Enter new username" v-model="newuname" required />
                        </div>
                    </form>
                    <form class="col-md-6">
                        <div class="form-group">
                            <label class="input_header_style" for="newpassword">New Password</label>
                            <input type="text" class="form-control contact_input_form" id="newpassword" placeholder="Enter new password" v-model="newpassword" required />
                        </div>
                    </form>
                </div>
                <div style="text-align: center; margin-top: 10px; margin-bottom: -30px;">
                    <button type="submit" class="cancel_btn" style="margin-right: 50px;" @click="backManage">Cancel</button>
                    <button type="submit" class="enable_btn" @click="saveChanges">Save Changes</button>
                    <div v-if="nochanges" class="warning-msg text-center">Please enter all fields</div>
                </div>
            </div>
        </div>
        <!--Lost Mode Confirmation-->
        <div class="loading-card" v-if="changessaved">
            <div class="loading-content">
                <div class="loading-text">
                    <img src="../assets/check.png" class="img_style">
                    <p class="notiftext_title">Profile Saved</p>
                    <p class="notiftext">You can change your profile again using the Edit Profile button again</p>
                    <button type="submit" class="mandev_btn text-center" @click="editDone">Done</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
        
<script>
    import SideNavbar from './SideNavbar.vue';
    import BackButton from './BackButton.vue';

    export default {
        components: {
        SideNavbar,
        BackButton,
        },
        data(){
            return{
                newuname: '',
                newpassword: '',
                overlayup: false,
                editprofile: false,
                changessaved: false,
                nochanges: false,
                success: false,
            };
        },
        methods: {
            goBack() {
                this.$router.replace('/dashboard'); // Go back one step in the history
            },
            goDashboard() {
                this.$router.replace('/dashboard');
            },
            editProfile() {
                this.editprofile = true;
                this.overlayup = true;
            },
            backManage(){
                this.editprofile = false;
                this.overlayup = false;
                this.nochanges = false;
            },
            saveChanges(){
                if(this.newuname == '' && this.newpassword == ''){
                    this.nochanges = true;
                }
                else{
                    this.changessaved = true;
                    this.editprofile = false;
                }
            },
            editDone(){
                this.overlayup = false;
                this.changessaved = false;
                this.editprofile = false;
            },
            logout(){
                localStorage.clear() 
                this.$router.replace('/')
            }
        }
    }
</script>
        
<style scoped>
.layout{
    display: flex;
}
.regdevice_contents{
    flex: 1;
    padding-left: 16px;
    font-family: 'Century Gothic';
}
.regdevice_header{
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 24px;
    margin-top: 5px;
}
.regdevice_secondary_header{
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 16px;
}
.regdevice_secondary_container{
    margin-top: 15px;
}
.regdevice_container{
    display: flex;
    max-height: 680px;
    justify-content: center;
    overflow-y: auto;
}

/*Card CSS style*/
.card {
    width: 950px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.card_content {
    padding: 10px;
}
.device_info {
    display: flex;
}
.device_image {
    width: 150px;
    height: 150px;
    margin-right: 20px;
}
.card_devicename_text{
    font-family: 'Century Gothic Bold';
    font-size: 18px;
    margin-bottom: 10px;
}
.card_details_strong{
    font-family: 'Century Gothic Bold';
    font-size: 14px;
    margin-bottom: 2px;
}
.card_details_text{
    font-family: 'Century Gothic';
    font-size: 14px;
    margin-bottom: 2px;
}
.button_container{
    display: flex;
    height: 55px;
    padding-left: 180px;
    background-color: #FFF6CE;
}
.lostmode_btn{
    margin-top: 10px;
    width: 110px;
    height: 35px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 5px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}
.lostmode_btn:hover{
    filter: brightness(90%);
}
.removebtn{
    padding-left: 530px;
}
.removedevice_btn{
    text-align: center;
    margin-top: 10px;
    width: 110px;
    height: 35px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 5px;
    padding: 0.3vw;
    background-color: #FC2929;
    color: #ffffff;
    transition: background-color 0.3s ease;
    font-weight: 700;
}
.removedevice_btn:hover{
    filter: brightness(90%);
}

/*Lost Mode Form Style*/
.input_header_style{
    font-family: 'Century Gothic Bold';
    font-size: 12px;
    color: #013042;
    margin-bottom: 2px;
}
.message_input_form{
    vertical-align: top !important;
    text-align: left !important;
    background-color: #ffffff;
    height: 100px;
    width: 350px;
    text-decoration: none solid rgba(0, 0, 0, 0.87);
    border-color: rgba(1, 48, 66, 0.2) !important;
    word-spacing: 0px;
    letter-spacing: 0.131118px;
    font-size: 12px !important;
    box-sizing: border-box;
    outline: rgba(0, 0, 0, 0.87) none 0px;
}
.message_input_form:focus{
    outline: none !important;
    box-shadow: none !important;
    border-color: rgba(1, 48, 66, 0.8) !important;
    border-width: 2.5px;
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
}
.contact_input_form{
    background-color: #ffffff;
    height: 35px;
    width: 350px;
    text-decoration: none solid rgba(0, 0, 0, 0.87);
    border-color: rgba(1, 48, 66, 0.2) !important;
    word-spacing: 0px;
    letter-spacing: 0.131118px;
    font-size: 12px !important;
    box-sizing: border-box;
    outline: rgba(0, 0, 0, 0.87) none 0px;
}
.contact_input_form:focus{
    outline: none !important;
    box-shadow: none !important;
    border-color: rgba(1, 48, 66, 0.8) !important;
    border-width: 2.5px;
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
}
::placeholder{
    font-size: 12px;
}

.enable_btn{
    text-align: center;
    width: 140px;
    height: 30px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}
.enable_btn:hover{
    filter: brightness(90%);
}
.cancel_btn{
    text-align: center;
    width: 140px;
    height: 30px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffffff;
    border-color: #013042;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}
.cancel_btn:hover{
    filter: brightness(90%);
}

/*Lost Mode Enable Form*/
.lostmode-card {
    position: fixed;
    width: 800px;
    height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: flex;
}
.lostmode-content {
    display: flex;
    padding-left: 20px;
}
.lostmode-text {
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 20px;
    padding-left: 20px;
    margin-bottom: -10px;
}
/*Lost mode confirmation style */
.img_style{
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}
.notiftext_title{
    font-family: 'Century Gothic Bold';
    font-size: 12px;
    color: #000000;
    margin-bottom: 2px;
}
.notiftext{
    font-family: 'Century Gothic';
    text-align: center !important;
    font-size: 12px;
    color: #000000;
    margin-bottom: 8px;
}
.mandev_btn{
    text-align: center;
    width: 150px;
    height: 30px;
    margin-bottom: 5px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}
.mandev_btn:hover{
    filter: brightness(90%);
}
.dashboard_btn{
    text-align: center;
    width: 150px;
    height: 30px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffffff;
    border-color: #013042;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}
.dashboard_btn:hover{
    filter: brightness(90%);
}
.loading-card {
    position: fixed;
    width: 280px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 28px;
    filter: brightness(98%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.loading-text {
    font-family: CenturyGothic, sans-serif;
    text-align: center;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 9998; /* Below the loading content */
}
.warning-msg {
    color: red;
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;
}
</style>
<template>
    <div class="layout">
        <div class="overlay" v-if="loading"></div>
        <div style="z-index: 105 !important;">
            <SideNavbar @button-click="langchange"></SideNavbar>
        </div>
        <div class="regdevice_contents">
            <div class="regdevice_header">
                {{ this.lang.title }}
            </div>
            <div class="dashboard_role">
                Current Role : {{ this.currentRole }}
            </div><br>
            <div class="regdevice_secondary_container">
                <p class="regdevice_secondary_header">{{ this.lang.second_title }}</p>
            </div>
            <div class="searchbar_container form-group form_margin">
                <div v-if="!select_option_pos">
                    <input type="text" class="search_field" @click="searchPos" id="pos_account"
                        :placeholder="this.lang.accounts_search_hint" v-model="pos_acc_search" required
                        v-on:keyup.enter="searchPos" />
                    <button type="submit" class="editacc_btn" @click="searchAccounts">{{ this.lang.search_button }}</button>
                </div>
            </div>
            <div v-if="!select_option_pos">
                <div class="table_container">
                    <table class="table_styles">
                        <thead class="table_header">
                            <tr>
                                <th class="tablehead_text">{{ this.lang.header_user_accounts[0] }}</th>
                                <th class="tablehead_text">{{ this.lang.header_user_accounts[1] }}</th>
                                <th class="tablehead_text">{{ this.lang.header_user_accounts[2] }}</th>
                                <th class="tablehead_text">{{ this.lang.header_user_accounts[3] }}</th>
                                <th class="tablehead_text">{{ this.lang.header_user_accounts[4] }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in tableDataAccounts" :key="index"
                                :class="{ 'odd_row': index % 2 === 0, 'even_row': index % 2 !== 0 }"
                                class="table_body_border">
                                <td class="tablecolumn_text">{{ item.username }}</td>
                                <td class="tablecolumn_text">{{ item.password }}</td>
                                <td class="tablecolumn_text">{{ item.role }}</td>
                                <td class="tablecolumn_text">{{ item.pos_codename }}</td>
                                <td  v-if="item.role != 'Manager'">
                                    <button v-if="item.role != 'Point-of-Sales Manager'" type="submit" class="removeacc_btn"
                                        @click="onAboutToRemoveAccount(item.id)">{{ this.lang.remove_entry }}</button>
                                    <button type="submit" @click="onOpenEditAccounts(item.id)" class="editacc_btn"
                                        id="account_btn_selector">{{ this.lang.edit_entry }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center">
                    <button type="submit" class="reg_btn" @click="onOpenAddAccounts">{{ this.lang.add_account
                        }}</button>
                </div>
            </div>
            <!--Adding Accounts-->
            <div class="lostmode-card-account">
                <div class="row">
                    <div class="lostmode-text">
                        {{ this.lang.account_form_header[0] }}
                    </div>
                    <div class="lostmode-content">
                        <div style="padding: 10px;">
                            <label class="input_header_style" for="account_username"
                                style="font-family: 'Century Gothic Bold';">{{ this.lang.account_form_header[2]
                                }}:</label>
                            <input type="text" class="form-control contact_input_form" id="account_username"
                                :placeholder="this.lang.account_form_header[6]" v-model="formDataAcc.username"
                                required />
                        </div>
                        <div style="padding: 10px;">
                            <label class="input_header_style" for="account_password"
                                style="font-family: 'Century Gothic Bold';">{{ this.lang.account_form_header[3]
                                }}:</label>
                            <input type="text" class="form-control contact_input_form" id="account_password"
                                :placeholder="this.lang.account_form_header[7]" v-model="formDataAcc.password"
                                required />
                        </div>
                        <div style="padding: 10px;">
                            <label class="input_header_style" for="account_role"
                                style="font-family: 'Century Gothic Bold';">{{ this.lang.account_form_header[4]
                                }}:</label>
                            <select id="account_role" v-model="formDataAcc.role" class="form-control contact_input_form"
                                label="Select branch code">
                                <option v-for="role in role_list" :key="role" :value="role">{{ role }}</option>
                            </select>
                        </div>
                        <div style="padding: 10px;">
                            <label class="input_header_style" for="account_branch"
                                style="font-family: 'Century Gothic Bold';">{{ this.lang.account_form_header[5]
                                }}:</label>
                            <h5>{{ this.formDataAcc.branch }}</h5>
                        </div>
                        <div style="text-align: center; margin-top: 10px; ">
                            <button type="submit" class="enable_btn" @click="onOpenPermissionsFromAddAccount">{{
                                this.lang.account_form_header[12] }}</button>
                            <br><button id="create_accounts_btn" type="submit" class="enable_btn" @click="createAccounts" :disabled="!disable_add_btn ? true : false">{{
                                this.lang.account_form_header[10] }}</button><br><br>
                            <button type="submit" class="cancel_btn" @click="onCloseAddAccounts"
                                style="background-color: red; color: #fff;">{{ this.lang.account_form_header[11]
                                }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lostmode-card-account-edit">
                <div class="row">
                    <div class="lostmode-text">
                        {{ this.lang.account_form_header[1] }}
                    </div>
                    <div class="lostmode-content">
                        <div style="padding: 10px;">
                            <label class="input_header_style" for="account_username">{{ this.lang.account_form_header[2]
                                }}:</label>
                            <input type="text" class="form-control contact_input_form" id="account_username"
                                :placeholder="this.lang.account_form_header[6]" v-model="formDataAcc.username"
                                required />
                        </div>
                        <div style="padding: 10px;">
                            <label class="input_header_style" for="account_password">{{ this.lang.account_form_header[3]
                                }}:</label>
                            <input type="password" class="form-control contact_input_form" id="account_password"
                                :placeholder="this.lang.account_form_header[7]" v-model="formDataAcc.password"
                                :disabled="!password_change" />
                            <button type="submit" class="cancel_btn" style="background-color: #FFD600;"
                                @click="enablePassword">{{ this.lang.account_form_header[13] }}</button>
                        </div>
                        <div style="padding: 10px;">
                            <label class="input_header_style" for="account_role"
                                style="font-family: 'Century Gothic Bold';">{{ this.lang.account_form_header[4]
                                }}:</label>
                            <select id="account_role" v-model="formDataAcc.role" class="form-control contact_input_form"
                                label="Select branch code">
                                <option v-for="role in role_list" :key="role" :value="role">{{ role }}</option>
                            </select>
                        </div>
                        <div style="padding: 10px;">
                            <label class="input_header_style" for="account_branch"
                                style="font-family: 'Century Gothic Bold';">{{ this.lang.account_form_header[5]
                                }}:</label>
                            <h5>{{ this.formDataAcc.branch }}</h5>
                        </div>
                        <div style="text-align: center; margin-top: 10px;">
                            <button type="submit" class="enable_btn" @click="onOpenPermissionsFromEditAccount">{{
                                this.lang.account_form_header[12] }}</button>
                            <br><button type="submit" class="enable_btn" @click="editAccount">{{
                                this.lang.account_form_header[1] }}</button><br><br>
                            <button type="submit" class="cancel_btn" @click="onCloseEditAccounts"
                                style="background-color: red; color: #fff;">{{ this.lang.account_form_header[11]
                                }}</button>

                        </div>
                    </div>
                </div>
            </div>
            <!--Permissions handling-->
            <div class="permissions_card">
                <div class="container">
                    <div class="row">
                        <div class="lostmode-text" style="font-size: x-large;">
                            {{ this.lang.permission_title_texts[0] }}
                        </div>
                        <div class="lostmode-content-permissions">
                            <form class="col-md-6">
                                <h5 style="font-family: 'Century Gothic Bold', sans-serif;">{{
                                    this.lang.permission_title_texts[1] }}</h5>
                                <div class="form-group" style="margin-bottom: 5px;">
                                    <input class="input_header_style" type="checkbox" v-model="permissions.M0000001"
                                        :id="'checkbox1_' + index">
                                    <label class="form-check-label" :for="'checkbox1_' + index"
                                        style="margin-left: 10px; margin-bottom: 5px;">{{
                                            this.lang.permission_texts_point_of_service[0] }}</label>
                                </div>
                                <div class="form-group" style="margin-bottom: 5px;">
                                    <input class="input_header_style" type="checkbox" v-model="permissions.M0000002"
                                        :id="'checkbox1_' + index">
                                    <label class="form-check-label" :for="'checkbox1_' + index"
                                        style="margin-left: 10px; margin-bottom: 5px;">{{
                                            this.lang.permission_texts_point_of_service[1] }}</label>
                                </div>
                                <div class="form-group" style="margin-bottom: 5px;">
                                    <input class="input_header_style" type="checkbox" v-model="permissions.M0000003"
                                        :id="'checkbox1_' + index">
                                    <label class="form-check-label" :for="'checkbox1_' + index"
                                        style="margin-left: 10px; margin-bottom: 5px;">{{
                                            this.lang.permission_texts_point_of_service[2] }}</label>
                                </div>
                            </form>
                            <form class="col-md-6">
                                <h5
                                    style="font-family: 'Century Gothic Bold', sans-serif; margin-top: 10px; margin-bottom: 10px;">
                                    {{ this.lang.permission_title_texts[3] }}</h5>
                                <div class="form-group" style="margin-bottom: 5px;">
                                    <input class="input_header_style" type="checkbox" v-model="permissions.M0000013"
                                        :id="'checkbox1_' + index">
                                    <label class="form-check-label" :for="'checkbox1_' + index"
                                        style="margin-left: 10px; margin-bottom: 5px;">{{
                                            this.lang.permission_texts_user_management[2] }}</label>
                                </div>
                                <div class="form-group" style="margin-bottom: 5px;">
                                    <input class="input_header_style" type="checkbox" v-model="permissions.M0000014"
                                        :id="'checkbox1_' + index">
                                    <label class="form-check-label" :for="'checkbox1_' + index"
                                        style="margin-left: 10px; margin-bottom: 5px;">{{
                                            this.lang.permission_texts_user_management[3] }}</label>
                                </div>
                                <h5
                                    style="font-family: 'Century Gothic Bold', sans-serif; margin-top: 10px; margin-bottom: 10px;">
                                    {{ this.lang.permission_title_texts[5] }}</h5>
                                <div class="form-group" style="margin-bottom: 5px;">
                                    <input class="input_header_style" type="checkbox" v-model="permissions.M0000017"
                                        :id="'checkbox1_' + index">
                                    <label class="form-check-label" :for="'checkbox1_' + index"
                                        style="margin-left: 10px; margin-bottom: 5px;">{{
                                            this.lang.permission_texts_reporting[0] }}</label>
                                </div>
                                <div class="form-group" style="margin-bottom: 5px;">
                                    <input class="input_header_style" type="checkbox" v-model="permissions.M0000018"
                                        :id="'checkbox1_' + index">
                                    <label class="form-check-label" :for="'checkbox1_' + index"
                                        style="margin-left: 10px; margin-bottom: 5px;">{{
                                            this.lang.permission_texts_reporting[1] }}</label>
                                </div>
                            </form>
                        </div>
                        <div style="text-align: center; margin-top: -30px;">
                            <button type="submit" class="enable_btn" style="margin-right: 50px"
                                @click="onClosePermissionsSavingDataToAdd">{{ this.lang.permission_title_texts[6]
                                }}</button><br>
                            <br><button type="submit" class="cancel_btn" style="margin-right: 50px;"
                                @click="onClosePermissionsNotSavingDataToAdd">{{ this.lang.permission_title_texts[7]
                                }}</button>

                        </div>
                    </div>
                </div>
            </div>
            <!--Confirmation Prompt-->
            <div class="loading-card" v-if="removeaccount">
                <div class="loading-content">
                    <div class="loading-text">
                        <img src="../assets/warning.png" class="img_style">
                        <p class="notiftext_title">{{ this.lang.notice_title }}</p>
                        <p class="notiftext">{{ this.lang.notice_text }}</p>
                        <button type="submit" class="mandev_btn text-center" @click="onRemoveAccounts">{{
                            this.lang.notice_button1 }}</button>
                        <button type="submit" class="dashboard_btn text-center" @click="onCancelRemoveAccount">{{
                            this.lang.notice_button2 }}</button>
                    </div>
                </div>
            </div>
            <!--Loading Command-->
            <div class="loading-card" v-if="loadingCommand">
                <div class="loading-content">
                    <div class="loading-text">
                        <img src="../assets/loading.gif" class="img_style">
                        <p class="notiftext_title">{{ this.lang.popup_card_loading[1] }}</p>
                    </div>
                </div>
            </div>
            <!--Sucess Add Account command-->
            <div class="loading-card" v-if="success_add_account">
                <div class="loading-content">
                    <div class="loading-text">
                        <img src="../assets/check.png" class="img_style">
                        <p class="notiftext_title" style="margin-left: 10px;">{{ this.lang.popup_card_success_add_account[0] }}</p>
                        <p class="notiftext">{{ this.lang.popup_card_success_add_account[1] }}</p>
                        <button type="submit" class="mandev_btn text-center" style="margin-top: 20px;"
                            @click="onSuccessReturnToHomepage('acc_add')">{{ this.lang.popup_card_success_add_account[2] }}</button>
                    </div>
                </div>
            </div>
            <!--Sucess Edit Account command-->
            <div class="loading-card" v-if="success_edit_account">
                <div class="loading-content">
                    <div class="loading-text">
                        <img src="../assets/check.png" class="img_style">
                        <p class="notiftext_title" style="margin-left: 10px;">{{ this.lang.popup_card_success_entry_edit[0] }}</p>
                        <p class="notiftext">{{ this.lang.popup_card_success_entry_edit[1] }}</p>
                        <button type="submit" class="mandev_btn text-center" style="margin-top: 20px;"
                            @click="onSuccessReturnToHomepage('acc_edit')">{{ this.lang.popup_card_success_entry_edit[2] }}</button>
                    </div>
                </div>
            </div>
            <!--Sucess Remove Account command-->
            <div class="loading-card" v-if="success_remove_account">
                <div class="loading-content">
                    <div class="loading-text">
                        <img src="../assets/check.png" class="img_style">
                        <p class="notiftext_title" style="margin-left: 10px;">{{ this.lang.popup_card_success_remove_account[0] }}</p>
                        <p class="notiftext">{{ this.lang.popup_card_success_remove_account[1] }}</p>
                        <button type="submit" class="mandev_btn text-center" style="margin-top: 20px;"
                            @click="onSuccessReturnToHomepage('acc_remove')">{{ this.lang.popup_card_success_remove_account[2] }}</button>
                    </div>
                </div>
            </div>
            <!--Fail command-->
            <div class="loading-card" v-if="error_add">
                <div class="loading-content">
                    <div class="loading-text">
                        <img src="../assets/multiply.png" class="img_style">
                        <p class="notiftext_title">{{ this.lang.popup_card_fail[0] }}</p>
                        <p class="notiftext">{{ this.lang.popup_card_fail[1] }}</p>
                        <button type="submit" class="mandev_btn text-center" style="margin-top: 20px;"
                            @click="onFailReturnToHomepage">{{ this.lang.popup_card_fail[2] }}</button>
                    </div>
                </div>
            </div>
            <!--Access denied-->
            <div class="loading-card" v-if="page_unavailable">
                <div class="loading-content">
                    <div class="loading-text">
                        <img src="../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 15px;">
                        <p class="notiftext_title_denied_perms" style="margin-left: 20px;">{{
                            this.lang.popup_card_access_denied[0] }}</p>
                        <p class="notiftext_denied_perms">{{ this.lang.popup_card_access_denied[1] }}</p>
                        <button type="submit" class="mandev_btn text-center"
                            style="margin-left: 15px; margin-top: 20px;" @click="goBack">{{
                                this.lang.popup_card_access_denied[2] }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <BottomFooter ref="BottomFooterRef"></BottomFooter>
</template>

<script>
import SideNavbar from './SideNavbar.vue';
import BottomFooter from './BottomFooter.vue';

export default {
    components: {
        SideNavbar,
        BottomFooter
    },
    data() {
        return {
            page_unavailable: false,
            loading: false,
            removeaccount: false,
            loadingCommand: false,
            success_add_account: false,
            success_edit_account: false,
            success_remove_account: false,
            error_add: false,
            add_account_popup: false,
            show_permission: false,
            select_permission: false,
            toEditPerms: false,
            password_change: false,
            disable_add_btn: false,
            temp_codename: '',
            temp_account_id: '',
            currentRole: localStorage.role,
            formDataAcc: {
                id: -1,
                username: '',
                password: '',
                role: '',
                permssions: {},
                branch: ''
            },
            permissions: {
                M0000001: false,
                M0000002: false,
                M0000003: false,
                M0000004: false,
                M0000005: false,
                M0000006: false,
                M0000007: false,
                M0000008: false,
                M0000009: false,
                M0000010: false,
                M0000011: false,
                M0000012: false,
                M0000013: false,
                M0000014: false,
                M0000015: false,
                M0000016: false,
                M0000017: false,
                M0000018: false
            },
            tableData: [],
            tableDataAccounts: [],
            role_list: [],
            selectedAcc: '',
            lang: {
                title: "",
                second_title: "",
                content: "",
                accounts_search_hint: '',
                pos_search_hint: '',
                search_button: '',
                add_button: '',
                add_account: '',
                edit_entry: '',
                remove_entry: '',
                back_button: '',
                notice_title: '',
                notice_text: '',
                notice_button1: '',
                notice_button2: '',
                header: [],
                header_user_accounts: [],
                pos_form_header: [],
                account_form_header: [],
                permission_title_texts: [],
                permission_texts_point_of_service: [],
                permission_texts_device_management: [],
                permission_texts_user_management: [],
                permission_texts_template_management: [],
                permission_texts_reporting: [],
                popup_card_loading: [],
                popup_card_success_add_account: [],
                popup_card_success_entry_edit: [],
                popup_card_success_remove_account: [],
                popup_card_fail: [],
                popup_card_access_denied: []
            }
        };
    },
    methods: {
        searchAccounts() {
            const axios = require('axios');
            const dataInput = JSON.stringify({
                    token: localStorage.token,
                    branch_codename: localStorage.branch_codename,
                    account_name: this.pos_acc_search,
                })
            console.log(dataInput) 
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://api-master.ctrlytics.io/v1/dashboard/user/get-specific/search/pos',
                headers: { 
                    'Content-Type': 'application/json; charset=utf-8' 
                },
                data: dataInput
            };
            
            //Run API
            axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));

                if (response.status === 200) {
                    this.tableDataAccounts = response.data["result"].map(item => ({
                        id: item[0],
                        username: item[1],
                        password: item[2],
                        role: item[3],
                        pos_codename: item[4]
                    }));
                    
                }
                else {
                    console.log("Unable to retrieve extra data");
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        performAction() {
            this.$router.replace('/device-details');
        },
        goBack() {
            this.$router.replace('/dashboard');
        },
        viewPoS() {
            this.$router.replace('/edit-account');
        },
        addAccount() {
            this.$router.replace('/add-account');
        },
        removeConfirmation(code) {
            this.selectedAcc = code
            this.loading = true;
            this.removeaccount = true;
        },
        backManage() {
            this.selectedAcc = ''
            this.loading = false;
            this.removeaccount = false;
        },
        //Popup Management
        onOpenAddPointOfService() {
            this.loading = true;
            this.add_pos_popup = true;
            var lostmodeContent = document.querySelector('.lostmode-card-pos');
            if (lostmodeContent) {
                // Change the display property to 'flex'
                lostmodeContent.style.display = 'flex';
            } else {
                console.error("Element with class 'lostmode-content' not found.");
            }
        },
        onOpenAddAccounts() {
            //Handle load management
            this.loading = true;
            this.add_account_popup = true;

            //Make card visible
            var lostmodeContent = document.querySelector('.lostmode-card-account');
            if (lostmodeContent) {
                // Change the display property to 'flex'
                lostmodeContent.style.display = 'flex';
            } else {
                console.error("Element with class 'lostmode-card' not found.");
            }
            //var dropdown = document.getElementById("account_branch_add");
            //dropdown.options[0].selected = true;
            //dropdown.dispatchEvent(new Event('change'));

            var dropdown_role = document.getElementById("account_role");
            dropdown_role.options[0].selected = true;
            dropdown_role.dispatchEvent(new Event('change'));

        },
        onCloseEditPointOfService() {
            this.loading = false;
            var lostmodeContent = document.querySelector('.lostmode-card-pos-edit');
            if (lostmodeContent) {
                // Change the display property to 'flex'
                lostmodeContent.style.display = 'none';
            } else {
                console.error("Element with class 'lostmode-card' not found.");
            }

        },
        onCloseEditAccounts() {
            this.formDataAcc.id = -1;
            this.formDataAcc.username = ''
            this.formDataAcc.password = ''
            this.formDataAcc.role = ''
            this.formDataAcc.permssions = {}

            this.loading = false;
            this.toEditPerms = false;
            var lostmodeContent = document.querySelector('.lostmode-card-account-edit');
            if (lostmodeContent) {
                // Change the display property to 'flex'
                lostmodeContent.style.display = 'none';
            } else {
                console.error("Element with class 'lostmode-card' not found.");
            }
        },
        onOpenEditAccounts(account_id) {
            this.loading = true;
            this.toEditPerms = true;
            this.temp_account_id = account_id.toString();
            // Run API to pull details
            const axios = require('axios');
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: 'https://api-master.ctrlytics.io/v1/dashboard/user/get-specific',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                data: JSON.stringify({
                    token: localStorage.token,
                    account_id: account_id.toString(),
                })
            };

            // Request time!
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));

                    if (response.status === 200) {
                        //Implement stuff here
                        this.formDataAcc.id = response.data.result[0]

                        // Get everything
                        this.formDataAcc.username = response.data.result[1]
                        this.formDataAcc.password = response.data.result[2]
                        this.formDataAcc.role = response.data.result[3]
                        this.formDataAcc.branch = response.data.result[4]

                        const permissions_var = JSON.parse(response.data.result[5]);
                        console.log(permissions_var.permissions.M0000001);
                        //Handle permissions
                        this.permissions.M0000001 = permissions_var.permissions.M0000001;
                        this.permissions.M0000002 = permissions_var.permissions.M0000002;
                        this.permissions.M0000003 = permissions_var.permissions.M0000003;
                        this.permissions.M0000004 = permissions_var.permissions.M0000004;
                        this.permissions.M0000005 = permissions_var.permissions.M0000005;
                        this.permissions.M0000006 = permissions_var.permissions.M0000006;
                        this.permissions.M0000007 = permissions_var.permissions.M0000007;
                        this.permissions.M0000008 = permissions_var.permissions.M0000008;
                        this.permissions.M0000009 = permissions_var.permissions.M0000009;
                        this.permissions.M0000010 = permissions_var.permissions.M0000010;
                        this.permissions.M0000011 = permissions_var.permissions.M0000011;
                        this.permissions.M0000012 = permissions_var.permissions.M0000012;
                        this.permissions.M0000013 = permissions_var.permissions.M0000013;
                        this.permissions.M0000014 = permissions_var.permissions.M0000014;
                        this.permissions.M0000015 = permissions_var.permissions.M0000015;
                        this.permissions.M0000016 = permissions_var.permissions.M0000016;
                        this.permissions.M0000017 = permissions_var.permissions.M0000017;
                        this.permissions.M0000018 = permissions_var.permissions.M0000018;
                        
                        localStorage.setItem("permissions", JSON.stringify(this.permissions));
                        //Popup management
                        this.loadingCommand = false;
                    }
                    else {
                        this.loadingCommand = false;
                        this.error_add = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingCommand = false;
                    this.error_add = true;
                });

            // Run command to enable the popup once data has been inserted
            var lostmodeContent = document.querySelector('.lostmode-card-account-edit');
            if (lostmodeContent) {
                // Change the display property to 'flex'
                lostmodeContent.style.display = 'flex';
            } else {
                console.error("Element with class 'lostmode-card' not found.");
            }
        },
        autoApplyPermissions() {
            const axios = require('axios');
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: 'https://api-master.ctrlytics.io/v1/dashboard/user/get-predefined-roles/specific',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                data: JSON.stringify({
                    token: localStorage.token,
                    role_name: this.formDataAcc.role
                })
            };

            axios.request(config)
                .then((response) => {

                    if (response.status === 200) {
                        var final_result = JSON.parse(response.data.result).permissions;
                        console.log(final_result.M0000001);
                        this.permissions.M0000001 = final_result.M0000001;
                        this.permissions.M0000002 = final_result.M0000002;
                        this.permissions.M0000003 = final_result.M0000003;
                        this.permissions.M0000004 = final_result.M0000004;
                        this.permissions.M0000005 = final_result.M0000005;
                        this.permissions.M0000006 = final_result.M0000006;
                        this.permissions.M0000007 = final_result.M0000007;
                        this.permissions.M0000008 = final_result.M0000008;
                        this.permissions.M0000009 = final_result.M0000009;
                        this.permissions.M0000010 = final_result.M0000010;
                        this.permissions.M0000011 = final_result.M0000011;
                        this.permissions.M0000012 = final_result.M0000012;
                        this.permissions.M0000013 = final_result.M0000013;
                        this.permissions.M0000014 = final_result.M0000014;
                        this.permissions.M0000015 = final_result.M0000015;
                        this.permissions.M0000016 = final_result.M0000016;
                        this.permissions.M0000017 = final_result.M0000017;
                        this.permissions.M0000018 = final_result.M0000018;
                        localStorage.setItem('permissions', this.permissions);
                        console.log(this.permissions);
                    }
                    else {
                        this.loadingCommand = false;
                        this.error_add = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingCommand = false;
                    this.error_add = true;
                });
        },
        onOpenPermissionsFromAddAccount() {

            //API Call
            if (!this.permissions.M0000001 && !this.permissions.M0000002 && !this.permissions.M0000003 && !this.permissions.M0000004 && !this.permissions.M0000005 && !this.permissions.M0000006 && !this.permissions.M0000007 && !this.permissions.M0000008 && !this.permissions.M0000009 && !this.permissions.M0000010 && !this.permissions.M0000011 && !this.permissions.M0000012 && !this.permissions.M0000013 && !this.permissions.M0000014 && !this.permissions.M0000015 && !this.permissions.M0000016 && !this.permissions.M0000017 && !this.permissions.M0000018) {
                const axios = require('axios');
                let config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: 'https://api-master.ctrlytics.io/v1/dashboard/user/get-predefined-roles/specific',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    data: JSON.stringify({
                        token: localStorage.token,
                        role_name: this.formDataAcc.role
                    })
                };

                axios.request(config)
                    .then((response) => {

                        if (response.status === 200) {
                            var final_result = JSON.parse(response.data.result).permissions;
                            console.log(final_result.M0000001);
                            this.permissions.M0000001 = final_result.M0000001;
                            this.permissions.M0000002 = final_result.M0000002;
                            this.permissions.M0000003 = final_result.M0000003;
                            this.permissions.M0000004 = final_result.M0000004;
                            this.permissions.M0000005 = final_result.M0000005;
                            this.permissions.M0000006 = final_result.M0000006;
                            this.permissions.M0000007 = final_result.M0000007;
                            this.permissions.M0000008 = final_result.M0000008;
                            this.permissions.M0000009 = final_result.M0000009;
                            this.permissions.M0000010 = final_result.M0000010;
                            this.permissions.M0000011 = final_result.M0000011;
                            this.permissions.M0000012 = final_result.M0000012;
                            this.permissions.M0000013 = final_result.M0000013;
                            this.permissions.M0000014 = final_result.M0000014;
                            this.permissions.M0000015 = final_result.M0000015;
                            this.permissions.M0000016 = final_result.M0000016;
                            this.permissions.M0000017 = final_result.M0000017;
                            this.permissions.M0000018 = final_result.M0000018;
                            localStorage.setItem('permissions', this.permissions);
                        }
                        else {
                            this.loadingCommand = false;
                            this.error_add = true;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loadingCommand = false;
                        this.error_add = true;
                    });
            }

            //Window management
            var lostmodeContent = document.querySelector('.permissions_card');
            var accountPermissionsContent = document.querySelector('.lostmode-card-account');
            if (lostmodeContent) {
                // Change the display property to 'flex'
                lostmodeContent.style.display = 'flex';
                accountPermissionsContent.style.display = 'none';
            } else {
                console.error("Element not found");
            }
        },
        onOpenPermissionsFromEditAccount() {
            var lostmodeContent = document.querySelector('.lostmode-card-account-edit');
            var accountPermissionsContent = document.querySelector('.permissions_card');
            if (lostmodeContent) {
                // Change the display property to 'flex'
                this.toEditPerms = true;
                lostmodeContent.style.display = 'none';
                if (!localStorage.getItem("permissions")) {
                    console.log("Retrieving permissions from API")
                    this.retrieveUserPerms(this.formDataAcc.id);
                } else {
                    console.log("Retrieving permissions from localStorage")
                    this.permissions = JSON.parse(localStorage.getItem("permissions"));
                }
                //this.permissions = this.temp_perms_storage;
                console.log("Temporarily storing permissions...");
                console.log(this.temp_perms_storage);
                console.log("Temporarily restoring permissions from temp...");
                accountPermissionsContent.style.display = 'flex';
            }    
            else {
                console.error("Element not found");
            }
        },
        onClosePermissionsNotSavingDataToAdd() {
            var lostmodeContent;
            var accountPermissionsContent;
            //if (this.toEditPerms == true) {
            console.log(this.toEditPerms);
            if (this.toEditPerms) {
                lostmodeContent = document.querySelector('.lostmode-card-account-edit');
            } else {
                lostmodeContent = document.querySelector('.lostmode-card-account');
            }
                accountPermissionsContent = document.querySelector('.permissions_card');
                console.log(this.permissions);
                console.log("Temporarily restoring permissions from temp...");

                console.log("Returning to previous perms");
                console.log(this.temp_perms_storage);
                lostmodeContent.style.display = 'flex';
                accountPermissionsContent.style.display = 'none';
                return;
        },
        onClosePermissionsSavingDataToAdd() {
            var lostmodeContent;
            if (this.toEditPerms) {
                lostmodeContent = document.querySelector('.lostmode-card-account-edit');
            } else {
                lostmodeContent = document.querySelector('.lostmode-card-account');
                document.getElementById("create_accounts_btn").style.backgroundColor = "#ffd600";
                this.disable_add_btn = true
            }
            var accountPermissionsContent = document.querySelector('.permissions_card');
            if (lostmodeContent) {
                // Change the display property to 'flex'
                lostmodeContent.style.display = 'flex';
                console.log(this.permissions);
                localStorage.setItem("permissions", JSON.stringify(this.permissions));
                console.log("Current temp perms are: ");
                console.log(this.temp_perms_storage);

                //Switch back to accounts
                accountPermissionsContent.style.display = 'none';
            } else {
                console.error("Element not found");
            }
        },
        onCloseAddPointOfService() {
            this.loading = false;
            this.add_pos_popup = false;
            var lostmodeContent = document.querySelector('.lostmode-card-pos');
            if (lostmodeContent) {
                // Change the display property to 'flex'
                lostmodeContent.style.display = 'none';
            } else {
                console.error("Element with class 'lostmode-card' not found.");
            }
            console.log("Saved data not found");
        },
        onCloseAddAccounts() {
            this.loading = false;
            var lostmodeContent = document.querySelector('.lostmode-card-account');
            if (lostmodeContent) {
                document.getElementById("create_accounts_btn").style.backgroundColor = "#d3d3d3";
                this.disable_add_btn = false;
                // Change the display property to 'flex'
                lostmodeContent.style.display = 'none';
            } else {
                console.error("Element with class 'lostmode-content' not found.");
            }
        },
        onFailReturnToHomepage() {
            this.loading = false;
            this.error_add = false;
            window.location.reload();
        },
        onSuccessReturnToHomepage(stringPopup) {
            this.loading = false;
            switch(stringPopup) {
                case "acc_add":
                    this.success_add_account = false;
                    break;
                case "acc_edit":
                    this.success_edit_account = false;
                    break;
                case "acc_remove":    
                    this.success_remove_account = false;
                    break;
            }
            window.location.reload();
        },
        //Removal commands
        onTriggerRemoveAccounts(account_id_selected) {
            this.loading = true;
            this.temp_account_id = account_id_selected
            this.removeaccount = true;
        },
        onAboutToRemoveAccount(account_id) {
            this.temp_account_id = account_id;
            this.loading = true;
            this.removeaccount = true;
        }, 
        onRemoveAccounts() {
            this.removeaccount = false;
            this.loadingCommand = true;
            console.log(this.temp_account_id);
            var account_id_str = this.temp_account_id.toString();
            //Run API
            //Load the data based on specific code
            const axios = require('axios');
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: 'https://api-master.ctrlytics.io/v1/dashboard/user/remove',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                data: JSON.stringify({
                    token: localStorage.token,
                    account_id: account_id_str
                })
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));

                    if (response.status === 200) {
                        this.loadingCommand = false;
                        this.success_edit_account = true;
                    }
                    else {
                        this.loadingCommand = false;
                        this.error_add = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingCommand = false;
                    this.error_add = true;
                });
            //Bring success prompt
        },
        onCancelRemoveAccount() {
            this.removeaccount = false;
            this.loading = false;
        },
        //Auxiliary commands
        retrieveAllRolesAndSetDefaultPermissions() {
            const axios = require('axios');
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: 'https://api-master.ctrlytics.io/v1/dashboard/user/get-predefined-roles/pos',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                data: JSON.stringify({
                    token: localStorage.token,
                })
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));

                    if (response.status === 200) {
                        this.role_list = response.data.result;
                    }
                    else {
                        this.loadingCommand = false;
                        this.error_add = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingCommand = false;
                    this.error_add = true;
                });
        },
        //API Management
        
        async createAccounts() {
            //Closes popup
            this.add_account_popup = false;
            this.loadingCommand = true;

            var lostmodeContent = document.querySelector('.lostmode-card-account');
            if (lostmodeContent) {
                lostmodeContent.style.display = 'none';
                document.getElementById("create_accounts_btn").style.backgroundColor = "#d3d3d3";
                this.disable_add_btn = false;
            } else {
                console.error("Element with class 'lostmode-content' not found.");
            }

            const axios = require('axios');
            if (!localStorage.getItem("permissions")) {
                //Get the permissions directly from the API
                console.log("Triggered permissons auto population");
                await this.autoApplyPermissions();
                console.log(JSON.parse(localStorage.getItem('permissions')));
            }
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: 'https://api-master.ctrlytics.io/v1/dashboard/user/add',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                data: JSON.stringify({
                    token: localStorage.token,
                    username: this.formDataAcc.username,
                    password: this.formDataAcc.password,
                    role: this.formDataAcc.role,
                    permissions: JSON.stringify({ "permissions": JSON.parse(localStorage.getItem('permissions'))}),
                    branch_codename: this.formDataAcc.branch
                })
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    if (response.status === 200) {
                        localStorage.removeItem('permissions');
                        this.loadingCommand = false;
                        this.success_add_account = true;
                    }
                    else {
                        this.loadingCommand = false;
                        this.error_add = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingCommand = false;
                    this.error_add = true;
                });
        },
        editAccount() {
            //Closes popup
            var lostmodeContent = document.querySelector('.lostmode-card-account-edit');
            if (lostmodeContent) {
                // Change the display property to 'flex'
                lostmodeContent.style.display = 'none';
            } else {
                console.error("Element with class 'lostmode-card' not found.");
            }
            this.loadingCommand = true;

            let url_req = '';
            let data = '';
            console.log(this.temp_account_id);

            if (this.password_change) {
                url_req = 'https://api-master.ctrlytics.io/v1/dashboard/user/edit';
                data = JSON.stringify({
                    token: localStorage.token,
                    account_id: this.temp_account_id,
                    username: this.formDataAcc.username,
                    password: this.formDataAcc.password,
                    role: this.formDataAcc.role,
                    permissions: JSON.stringify({ "permissions": this.permissions }),
                    branch_codename: this.formDataAcc.branch
                })
            } else {
                url_req = 'https://api-master.ctrlytics.io/v1/dashboard/user/edit/no-password';
                data = JSON.stringify({
                    token: localStorage.token,
                    account_id: this.temp_account_id,
                    username: this.formDataAcc.username,
                    role: this.formDataAcc.role,
                    permissions: JSON.stringify({ "permissions": this.permissions }),
                    branch_codename: this.formDataAcc.branch
                })
            }
            console.log(data);
            const axios = require('axios');
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: url_req,
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    if (response.status === 200) {
                        this.loadingCommand = false;
                        this.success_edit_account = true;
                    }
                    else {
                        this.loadingCommand = false;
                        this.error_add = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingCommand = false;
                    this.error_add = true;
                });
        },
        enablePassword() {
            this.password_change = true;
        },
        removeAcc() {
            //Bring out the popup
            this.loading = true;
            this.loadingCommand = true;

            const axios = require('axios');
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: 'https://api-master.ctrlytics.io/v1/dashboard/user/remove',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                data: JSON.stringify({
                    token: localStorage.token,
                    codename: this.formDataAcc.id
                })
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    if (response.status === 200) {
                        this.loadingCommand = false;
                        this.success_remove_account = true;
                    }
                    else {
                        this.loadingCommand = false;
                        this.error_add = true;
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.loadingCommand = false;
                    this.error_add = true;
                });

        },
        //On load page
        loadAccounts() {
            const axios = require('axios');
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: 'https://api-master.ctrlytics.io/v1/dashboard/user/get-specific/search/pos',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                data: JSON.stringify({
                    token: localStorage.token,
                    branch_codename: localStorage.branch_codename,
                    account_name: ''
                })
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    this.tableDataAccounts = response.data["result"].map(item => ({
                        id: item[0],
                        username: item[1],
                        password: item[2],
                        role: item[3],
                        pos_codename: item[4]
                    }));
                    if (response.status != 200) {
                        this.loading = true;
                        this.page_unavailable = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = true;
                    this.page_unavailable = true;

                });

        },
        async langchange(picker) {
            console.log(picker)
            console.log(this.data)
            const response = await fetch("/manageuseraccounts.json");
            this.$refs.BottomFooterRef.langchange(picker)
            const data = await response.json();
            this.data = data
            this.lang = this.data[picker]
        }
    },
    mounted() {
        console.log(this.page_unavailable)
        this.loadAccounts();
        this.retrieveAllRolesAndSetDefaultPermissions();
        this.formDataAcc.branch = localStorage.getItem('branch_codename');
        document.getElementById("create_accounts_btn").style.backgroundColor = "#d3d3d3";
        this.select_option_pos = false;
    }
}


</script>

<style scoped>
.logo {
    width: 20px;
    cursor: pointer;
}

.layout {
    display: flex;
}

.regdevice_contents {
    flex: 1;
    padding-left: 16px;
    font-family: 'Century Gothic';
}

.regdevice_header {
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 24px;
    margin-top: 5px;
}

.regdevice_secondary_header {
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 20px;
}

.regdevice_secondary_container {
    margin-top: 5px;
}

.contents_header_text {
    font-size: 14px;
    margin-top: -15px;
}

.form_margin {
    margin-top: -6px;
}

.search_field {
    margin-left: 20px;
}

.custom_input_form {
    background-color: #ffffff;
    height: 35px;
    width: 300px;
    text-decoration: none solid rgba(0, 0, 0, 0.87);
    border-color: rgba(1, 48, 66, 0.2) !important;
    word-spacing: 0px;
    letter-spacing: 0.131118px;
    font-size: 12px !important;
    box-sizing: border-box;
    outline: rgba(0, 0, 0, 0.87) none 0px;
}

.custom_input_form:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: rgba(1, 48, 66, 0.8) !important;
    border-width: 2.5px;
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
}

::placeholder {
    font-size: 12px;
}

.searchbar_container {
    display: flex;
}

.table_container {
    margin-top: 15px;
    max-width: 1150px;
    max-height: 370px;
    overflow-y: auto;
}

.table_container::-webkit-scrollbar {
    width: 8px;
}

.table_container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.table_container::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 4px;
}

.table_styles {
    width: 100%;

}

thead tr {
    height: 50px;
}

th,
td {
    text-align: center;
    padding: 16px;
}

.odd_row {
    background-color: #ffffff;
}

.even_row {
    background-color: #e6e6e6;
}

.table_header {
    background-color: #cccccc;
    text-align: center;
    border-bottom: 1px solid #989898;
}

.table_body_border {
    border-bottom: 1px solid #989898;
}

.tablehead_text {
    font-family: "Century Gothic Bold", sans-serif;
    font-size: 14px;
    text-align: center;
}

.tablecolumn_text {
    font-family: "Century Gothic", sans-serif;
    font-size: 14px;
    text-align: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent overlay */
    z-index: 9998;
    /* Below the loading content */
}

.reg_btn {
    text-align: center;
    margin-top: 20px;
    width: 180px;
    height: 35px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 12px;
    border: none;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}

.reg_btn:hover {
    filter: brightness(90%);
}

.editacc_btn {
    margin-left: 10px;
    width: 110px;
    height: 35px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 5px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}

.editacc_btn:hover {
    filter: brightness(90%);
}

.removeacc_btn {
    text-align: center;
    width: 110px;
    height: 35px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 5px;
    padding: 0.3vw;
    background-color: #FC2929;
    color: #ffffff;
    transition: background-color 0.3s ease;
    font-weight: 700;
}

.loading-card {
    position: fixed;
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 28px;
    filter: brightness(98%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.loading-text {
    justify-items: center;
    display: grid;
    font-family: CenturyGothic, sans-serif;
    text-align: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent overlay */
    z-index: 9998;
    /* Below the loading content */
}

.removeacc_btn:hover {
    filter: brightness(90%);
}

.img_style {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}

.img_style_denied_perms {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    left: 10px;
}

.notiftext_title {
    font-family: 'Century Gothic Bold';
    font-size: 16px;
    color: #000000;
    margin-bottom: 2px;
}

.notiftext_title_denied_perms {
    font-family: 'Century Gothic Bold';
    font-size: 16px;
    color: #000000;
    margin-bottom: 2px;
}

.notiftext {
    font-family: 'Century Gothic';
    text-align: center !important;
    font-size: 12px;
    color: #000000;
    margin-bottom: 8px;
}

.notiftext_denied_perms {
    font-family: 'Century Gothic';
    text-align: center !important;
    font-size: 12px;
    color: #000000;
    margin-bottom: 8px;
}

.mandev_btn {
    text-align: center;
    width: 150px;
    height: 30px;
    margin-bottom: 5px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}

.mandev_btn:hover {
    filter: brightness(90%);
}

.dashboard_btn {
    text-align: center;
    width: 150px;
    height: 30px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffffff;
    border-color: #013042;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}

.dashboard_btn:hover {
    filter: brightness(90%);
}

/* Form popup management */
.lostmode-card-pos {
    position: absolute;
    width: 500px;
    height: 670px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;
}

.lostmode-card-pos-edit {
    position: absolute;
    width: 500px;
    height: 670px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;
}

.lostmode-card-account {
    position: absolute;
    width: 500px;
    height: 700px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;
}

.lostmode-card-account-edit {
    position: absolute;
    width: 500px;
    height: 700px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;
}

.permissions_card {
    position: absolute;
    width: 700px;
    height: 700px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;
}

.lostmode-content {
    display: absolute;
    padding-top: 7%;
    padding-bottom: 50px;
    width: 470px;
}

.lostmode-content-permissions {
    display: flex;
    padding-left: 4%;
    padding-top: 7%;
    padding-bottom: 50px;
    width: 940px;
}

.lostmode-text {
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 20px;
    padding-left: 20px;
    margin-bottom: -10px;
}

.enable_btn {
    text-align: center;
    width: 140px;
    height: 30px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    margin-top: 30px;
    font-weight: 700;
}

.enable_btn:hover {
    filter: brightness(90%);
}

.cancel_btn {
    text-align: center;
    width: 140px;
    height: 30px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffffff;
    border-color: #013042;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}

.cancel_btn:hover {
    filter: brightness(90%);
}

@media(min-width: 1900px) {
    .table_container {
        max-width: 1750px;
        max-height: 500px;
    }
}

@media(min-width: 1500px) {
    .table_container {
        max-width: 1400px;
        max-height: 570px;
    }
}

@media(max-width: 376px) {
    .regdevice_header {
        font-size: 14px
    }

    .table_container {
        max-width: 290px;
        max-height: 420px;
        overflow-x: auto;
    }

    .regdevice_secondary_container {
        width: 280px;
    }

    .regdevice_secondary_header {
        font-size: 12px;
    }

    .custom_input_form {
        width: 230px;
        height: 30px;
    }


}
</style>
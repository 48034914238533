<template>
<div class="layout">
    <div class="overlay" v-if="loading"></div>
    <div>
        <SideNavbar @button-click="langchange"></SideNavbar>
    </div>
    <div class="regdevice_contents">
        <BackButton @click="goBack" :message="this.lang.back_button"></BackButton>
        <div class="regdevice_header">
            {{ this.lang.title }}
        </div>
        <div class="dashboard_role">
          Current Role : {{ this.currentRole }}
      </div><br>
        <div class="regdevice_secondary_container">
            <p class="regdevice_secondary_header">{{ this.lang.second_title }}</p>
            <p class="contents_header_text">{{ this.lang.content }}</p>
        </div>
        <div class="regdevice_container">
            <div class="row">
                <form class="col-md-6">
                    <div class="form-group" style="margin-bottom: 5px;">
                        <label class="input_header_style" for="fullname">{{ this.lang.fullname }}</label>
                        <input type="text" class="form-control custom_input_form" id="fullname" :placeholder="this.lang.fullname_hint" v-model="fullname" required />
                    </div>
                    <div class="form-group" style="margin-bottom: 5px;">
                        <label class="input_header_style" for="msisdn">{{ this.lang.msisdn }}</label>
                        <input @focus="addPhoneCode" type="text" class="form-control custom_input_form" id="msisdn" :placeholder="this.lang.msisdn_hint" v-model="msisdn" required />
                    </div>
                    <div class="form-group" style="margin-bottom: 5px;">
                        <label class="input_header_style" for="serialnum">{{ this.lang.serialnum }}</label>
                        <input type="text" class="form-control custom_input_form" style="text-transform:uppercase;" id="serialnum" :placeholder="this.lang.serialnum_hint" v-model="serialnum" required />
                    </div>
                    <div class="form-group" style="margin-bottom: 5px;">
                        <label class="input_header_style" for="devicemodel">{{ this.lang.devicemodel }}</label>
                        <input type="text" class="form-control custom_input_form" id="devicemodel" :placeholder="this.lang.devicemodel_hint" v-model="devicemodel" required />
                    </div>
                    <div class="form-group" style="margin-bottom: 5px;">
                        <label class="input_header_style" for="contracttenure">{{ this.lang.contracttenure }}</label>
                        <input type="text" class="form-control custom_input_form" id="contracttenure" :placeholder="this.lang.contracttennue_hint" v-model="contracttenure" required />
                    </div>
                </form>
                <form class="col-md-6">
                    <div class="form-group" style="margin-bottom: 5px;">
                        <label class="input_header_style" for="nric">{{ this.lang.nric }}</label>
                        <input type="text" class="form-control custom_input_form" id="nric" :placeholder="this.lang.nric_hint" v-model="nric" required />
                    </div>
                    <div class="form-group" style="margin-bottom: 5px;">
                        <label class="input_header_style" for="email">{{ this.lang.email }}</label>
                        <input type="email" class="form-control custom_input_form" id="email" :placeholder="this.lang.email_hint" v-model="email" required />
                    </div>
                    <div class="form-group" style="margin-bottom: 5px;">
                        <label class="input_header_style" for="imei1">{{ this.lang.imei1 }}</label>
                        <input type="text" class="form-control custom_input_form" id="imei1" :placeholder="this.lang.imei1_hint" v-model="imei1" required />
                    </div>
                    <div class="form-group" style="margin-bottom: 5px;">
                        <label class="input_header_style" for="imei2">{{ this.lang.imei2 }}</label>
                        <input type="text" class="form-control custom_input_form" id="imei2" :placeholder="this.lang.imei2_hint" v-model="imei2" required />
                    </div>
                </form>
                <div class="text-center">
                    <div class="button_container">
                        <button type="submit" class="reg_btn" @click="register">{{ this.lang.register_button }}</button>
                        <div v-if="tryagainWarning" class="warning-msg text-center">{{ this.lang.tryagain }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loading-card" v-if="success">
            <div class="loading-content">
            <div class="loading-text">
                <p class="notiftext_title">{{ this.lang.notice_title }}</p>
                <p class="notiftext">{{ this.lang.notice_text }}</p>
                <img src="../assets/check.png" class="img_style">
                <div style="margin-top: 5px;">
                    <button style="margin-left: 10px;" type="submit" class="dashboard_btn text-center" @click="goDashboard">{{ this.lang.notice_button2 }}</button>
                </div>
            </div>
            </div>
        </div>
        <div class="loading-card" v-if="tryagainWarning">
            <div class="loading-content">
            <div class="loading-text">
                <p class="notiftext_title">{{ this.lang.error_prompt_title }}</p>
                <p class="notiftext">{{ this.lang.error_prompt_description }}</p>
                <img src="../assets/multiply.png" class="img_style">
                <div style="margin-top: 5px;">
                    <button style="margin-left: 10px;" type="submit" class="dashboard_btn text-center" @click="backToRegister">{{ this.lang.error_prompt_button1 }}</button>
                </div>
            </div>
            </div>
        </div>
        <!--Access denied-->
        <div class="loading-card" v-if="page_unavailable">
                <div class="loading-content">
                    <div class="loading-text">
                        <img src="../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 15px;">
                        <p class="notiftext_title_denied_perms" style="margin-left: 20px;">{{
                            this.lang.popup_card_access_denied[0] }}</p>
                        <p class="notiftext_denied_perms">{{ this.lang.popup_card_access_denied[1] }}</p>
                        <button type="submit" class="mandev_btn text-center"
                            style="margin-left: 15px; margin-top: 20px;" @click="goBack">{{
                                this.lang.popup_card_access_denied[2] }}</button>
                    </div>
                </div>
            </div>
    </div>
</div>
<BottomFooter ref="BottomFooterRef"></BottomFooter>
</template>
    
<script>
    import SideNavbar from './SideNavbar.vue';
    import BackButton from './BackButton.vue';
    import BottomFooter from './BottomFooter.vue';

    export default {
        components: {
        SideNavbar,
        BackButton,
        BottomFooter
        },
        data(){
            return{
                page_unavailable: false,
                fullname: '',
                serialnum: '',
                msisdn: '',
                devicemodel: '',
                email: '',
                nric: '',
                imei1: '',
                imei2: '',
                contracttenure: '',
                loading: false,
                success: false,
                tryagainWarning: false,
                isFilled: false,
                currentRole: localStorage.role,
                data:[],
                lang:{
                    title:"",
                    second_title:"",
                    content:"",
                    fullname: '',
                    fullname_hint: '',
                    serialnum: '',
                    serialnum_hint: '',
                    msisdn: '',
                    msisdn_hint: '',
                    devicemodel: '',
                    devicemodel_hint: '',
                    email: '',
                    email_hint : '',
                    nric: '',
                    nric_hint: '',
                    imei1: '',
                    imei1_hint: '',
                    imei2: '',
                    imei2_hint: '',
                    contracttenure: '',
                    contracttennue_hint: '',
                    back_button: '',
                    register_button: '',
                    tryagain:'',
                    notice_title: '',
                    notice_text:'',
                    notice_button1:'',
                    notice_button2:'',
                    popup_card_access_denied: [],
                }
            };
        },
        methods: {
            register(){
                if(this.msisdn == '' && this.serialnum == '' && this.email == '' 
                && this.fullname == '' && this.devicemodel == '' && this.email == '' && this.nric == ''
                && this.imei == '' && this.imei2 == '' && this.contracttenure == ''){
                    this.tryagainWarning = true;
                }
                else{
                    this.loading = true;
                    this.tryagainWarning = false;
                    console.log("Registering");
                    const axios = require('axios');

                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: 'https://api-master.ctrlytics.io/v1/dashboard/register/full-data?Full_Name='+this.fullname
                            +'&NRIC='+this.nric+'&Phone_No=+'+this.msisdn+'&Email='+this.email+'&Device_Serial_No='+this.serialnum.toUpperCase()
                            +'&IMEI='+this.imei1+'&Device_Model='+this.devicemodel+'&IMEI2='+this.imei2+'&Tenure='+this.contracttenure
                            +'&session_id='+localStorage.token,
                        headers: { }
                    };

                    axios.request(config)
                        .then((response) => {
                            if (response.data['status'] == 200) {
                                console.log(response['status']);
                                console.log(JSON.stringify(response.data));
                                this.success = true;
                                this.isFilled = true;
                            } else {
                                this.isFilled = true;
                                this.tryagainWarning = true;
                            }
                    })
                        .catch((error) => {
                            console.log(error);
                            this.tryagainWarning = true;
                    });
                    //setTimeout(() => {
                    //    this.success = true;
                    //}, 3000);
                }
            },
            checkLogin() {
                //Enable overlay
                this.loading = true;
                //Prep API
                const data = JSON.stringify({
                    token: localStorage.token
                });
                const axios = require('axios');
                let config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: 'https://api-master.ctrlytics.io/v1/login/validity-check',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                //Run API
                axios.request(config)
                    .then((response) => {
                        if (response.status === 200) {
                            this.loading = false;
                        }
                        else {
                            this.page_unavailable = true;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.page_unavailable = true;
                    });
            },
            
            addPhoneCode() {
                if (!this.msisdn.startsWith("60")) {
                    this.msisdn = "60" + this.msisdn;
                }
            },
            goBack() {
                this.$router.replace('/dashboard'); // Go back one step in the history
            },
            goManageDevice(){
                this.$router.replace('/device-list');
            },
            goDashboard(){
                this.$router.replace('/dashboard');
            },
            backToRegister(){
                location.reload();
            },
            async langchange(picker){
                console.log(picker)
                console.log(this.data)
                const response = await fetch("/registerdevice.json");
                const data = await response.json();
                this.data = data
                this.lang = this.data[picker]
                this.$refs.BottomFooterRef.langchange(picker)
                this.checkLogin();
            }
        },
        mounted() {
            this.langchange("ENG");
            
        }
    }
</script>
    
<style scoped>
.layout{
    display: flex;
}
.regdevice_contents{
    flex: 1;
    padding-left: 16px;
    font-family: 'Century Gothic';
}
.regdevice_header{
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 24px;
    margin-top: 5px;
}
.regdevice_secondary_header{
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 20px;
    margin-top: 15px;
}
.regdevice_secondary_container{
    margin-top: 30px;
}
.regdevice_container{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    margin-top: -5px;
}
.contents_header_text{
    font-size: 14px;
    margin-top: -15px;
}
.img_style{
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}
.notiftext_title{
    font-family: 'Century Gothic Bold';
    font-size: 12px;
    color: #000000;
    margin-bottom: 2px;
}
.notiftext{
    font-family: 'Century Gothic';
    text-align: center !important;
    font-size: 12px;
    color: #000000;
    margin-bottom: 8px;
}
.input_header_style{
    font-family: 'Century Gothic Bold';
    font-size: 12px;
    color: #013042;
    margin-bottom: 2px;
}
.reg_btn{
    text-align: center;
    margin-top: 20px;
    width: 140px;
    height: 30px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}
.reg_btn:hover{
    filter: brightness(90%);
}
.mandev_btn{
    text-align: center;
    width: 150px;
    height: 30px;
    margin-bottom: 5px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}
.mandev_btn:hover{
    filter: brightness(90%);
}
.button_container{
    width: auto;
}
.dashboard_btn{
    text-align: center;
    width: 150px;
    height: 30px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffffff;
    border-color: #013042;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}
.dashboard_btn:hover{
    filter: brightness(90%);
}
.custom_input_form{
    background-color: #ffffff;
    height: 35px;
    width: 400px;
    text-decoration: none solid rgba(0, 0, 0, 0.87);
    border-color: rgba(1, 48, 66, 0.2) !important;
    word-spacing: 0px;
    letter-spacing: 0.131118px;
    font-size: 12px !important;
    box-sizing: border-box;
    outline: rgba(0, 0, 0, 0.87) none 0px;
}
.custom_input_form:focus{
    outline: none !important;
    box-shadow: none !important;
    border-color: rgba(1, 48, 66, 0.8) !important;
    border-width: 2.5px;
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
}
::placeholder{
    font-size: 12px;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 9998; /* Below the loading content */
}
.warning-msg {
    color: red;
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;
}
.loading-card {
  position: fixed;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 28px;
  filter: brightness(98%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.loading-text {
    justify-items: center;
    display: grid;
    font-family: 'Century Gothic', sans-serif;
    text-align: center;
}

.img_style_denied_perms {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    left: 10px;
}

.notiftext_title_denied_perms {
    font-family: 'Century Gothic Bold';
    font-size: 16px;
    color: #000000;
    margin-bottom: 2px;
}

.notiftext_denied_perms {
    font-family: 'Century Gothic';
    text-align: center !important;
    font-size: 12px;
    color: #000000;
    margin-bottom: 8px;
}

@media(max-width: 376px){
    .custom_input_form{
        width: 280px;
    }
    .regdevice_secondary_container{
        margin-top: -10px;
    }
}
</style>
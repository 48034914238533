<template>
    <button class="back-button">
        {{ message }}
    </button>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
    .back-button {
        height: 25px;
        width: 70px;
        padding: 12px 32px 12px 32px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 10px;
        font-weight: bold;
        text-decoration: none solid rgb(1,48,66);
        border: none;
        border-radius: 10px;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: bold;
        justify-content: left;
        position: relative;
        margin-top: 10px;
        padding: 0;
    }
    .back-button:hover{
        filter: brightness(90%);
    }
    @media(max-width: 376px){
        .back-button{
            height: 23px;
            width: 60px;
            border-radius: 7px;
            font-size: 9px;
        }
    }
</style>
      
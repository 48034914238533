<template>
  <div>
    <router-view @executeFunction ="executeFunction"></router-view>
  </div>
</template>

<script>
export default {
    name: "App",
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body{
  background-color: #ffffff;
}
</style>

import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import * as Sentry from "@sentry/vue";

import App from './App.vue'
import LoginContents from './components/LoginScreen.vue'
import StartingDashboard from './components/StartDashboard.vue'
import FAQ from './components/FAQScreen.vue'
import RegisterDevice from './components/RegisterDevice.vue'
import UserProfile from './components/UserProfile.vue'
import ManageUserAdmin from './components/ManageUserAdmin.vue'
import DeviceReporting from './components/DeviceReporting.vue'
import ManageDevices from './components/ManageDevices.vue'
import DeviceDetails from './components/DeviceDetails.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap'

const app = createApp(App)
const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path: '/',
            components:{
                default: LoginContents,
            },
            params: true,   
        },
        {
            path: '/dashboard',
            component: StartingDashboard,
        },
        {
            path: '/manage-accounts',
            component: ManageUserAdmin,
        },
        {
            path: '/manage-devices',
            component: ManageDevices
        },
        {
            path: '/device-data-list',
            component: DeviceDetails
        },
        {
            path: '/faq',
            component: FAQ,
        },
        {
            path: '/register-devices',
            component: RegisterDevice,
        },
        {
            path: '/profile',
            component: UserProfile,
        },
        {
            path: '/reporting',
            component: DeviceReporting
        }
    ]
})
Sentry.init({
    app,
    dsn: "https://1dc91bf9ed1589cde427e56cb261d24f@o4505485535346688.ingest.us.sentry.io/4507490603106304",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

app.use(router).mount('#app')
